import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../../Pages/Accueil/Accueil.css";
import CircularProgressBar from "../../components/assets/CircularProgressBar";
import PDFViewer from "../../components/assets/PDFViewer";
import CustomPDFDisplay from "../../components/assets/CustomPDFDisplay";
import { useCurrentUser } from "../../components/hooks/useCurrentUser";
import Information from "../../Pages/MonCompte/Information";
import { useHistory } from "react-router-dom";
import {
  MDBContainer,
  MDBBtn,
  MDBIcon,
  MDBRow,
  MDBCol,
  MDBTable,
  MDBTableHead,
  MDBTableBody,
  MDBBadge,
} from "mdb-react-ui-kit";
import {
  getProfile,
  http,
  addCv,
} from "../../components/services/httpService";
import { useDarkMode } from "../../components/assets/DarkModeContext";

export default function MonCompte() {
  const [user, setUser] = useState({ nom: "", role: "", email: "", docUrl: "" });
  const [userCVs, setUserCVs] = useState([]);
  const [_email, setEmail] = useState("");
  const token = localStorage.getItem("token");
  const [activeTab, setActiveTab] = useState("Mes CVS");
  const [pdfFile, setPdfFile] = useState(null);
  const currentUser = useCurrentUser();
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const { isDarkMode } = useDarkMode();
  const history = useHistory();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      getProfile()
        .then((response) => response.json())
        .then((data) => {
          const { cvs, ...userData } = data;
          setUser(userData);
          setEmail(userData.email);

          if (Array.isArray(cvs)) {
            setUserCVs(cvs);
          } else {
            console.error("Data received is not an array", cvs);
          }
        })
        .catch((error) => console.error(error));
    }
  }, []);

  useEffect(() => {
    if (currentUser && currentUser.role === "CLIENT") {
      setActiveTab("Information"); 
    }
  }, [currentUser]);

  const [showBadge, setShowBadge] = useState({});

  async function handleClone(cvId) {
    try {
      const response = await http(`cv/clone/${cvId}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const clonedCv = await response.json();

      setUserCVs((prevCvs) => [...prevCvs, clonedCv]);
    } catch (error) {
      console.error(
        "There was a problem with the fetch operation: " + error.message
      );
    }
  }

  const handleDeleteCv = (cvId) => {
    // Si l'ID du CV est indéfini ou n'est pas valide
    if (!cvId) {
      const confirmed = window.confirm("Ce CV semble avoir un problème (pas d'ID valide). Voulez-vous le retirer de la liste ?");
      if (confirmed) {
        // Supprimez le CV "fantôme" de la liste côté client
        setUserCVs(userCVs.filter((cv) => cv !== cvId));
      }
      return;
    }

    const confirmed = window.confirm("Êtes-vous sûr de vouloir supprimer ce CV ?");
    if (!confirmed) {
      return;
    }

    const token = localStorage.getItem("token");
    http(`cv/delete/${cvId}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (response.ok) {
          setUserCVs(userCVs.filter((cv) => cv.id !== cvId));
        } else {
          console.error("Erreur lors de la suppression du CV :", response);
        }
      })
      .catch((error) => console.error(error));
  };

  const [isPopupVisible, setPopupVisible] = useState(true);

  const sortedCVs = [...userCVs].sort((a, b) => {
    const dateA = a.date_modification
      ? new Date(a.date_modification)
      : new Date(0);
    const dateB = b.date_modification
      ? new Date(b.date_modification)
      : new Date(0);

    return dateB - dateA;
  });

  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(function () {
        console.log("Texte copié dans le presse-papiers");
      })
      .catch((err) => {
        console.error("Erreur lors de la copie du texte : ", err);
      });
  };

  const handleCopyPdfLink = (user, cvId, mot_cle) => {

    const langue = userCVs.find(cv => cv.id === cvId)?.langues || "FR";
    const filename = generatePdfFilename(user, langue, mot_cle);

    const fullPdfUrl = `${baseUrl}cv/pdf-uploads/${filename}`;

    copyToClipboard(fullPdfUrl);

    setShowBadge((prevState) => ({ ...prevState, [cvId]: true }));

    setTimeout(() => {
      setShowBadge((prevState) => ({ ...prevState, [cvId]: false }));
    }, 3000);
  };

  function generatePdfFilename(user, langue, mot_cle) {
    return "ACO_" + user.prenom + "_" + user.nom.toUpperCase() + "_CV_" + langue?.toUpperCase() + "_" + mot_cle + ".pdf";
  }

  function handleEmailShare(user, cvId, mot_cle) {
    const langue = userCVs.find(cv => cv.id === cvId)?.langues || "FR";
    const filename = generatePdfFilename(user, langue, mot_cle);
    const fullPdfUrl = `${baseUrl}cv/pdf-uploads/${filename}`;
    const subject = encodeURIComponent("Lien vers mon CV");
    const body = encodeURIComponent(`Consultez mon CV ici: ${fullPdfUrl}`);
    window.open(`mailto:?subject=${subject}&body=${body}`, "_blank");
  }

  function handlePdfPreview(user, cvId, mot_cle) {
    const langue = userCVs.find(cv => cv.id === cvId)?.langues || "FR";
    const filename = generatePdfFilename(user, langue, mot_cle);
    const fullPdfUrl = `${baseUrl}cv/pdf-uploads/${filename}`;
    window.open(fullPdfUrl, '_blank');
  }

  function handleOriginPdfPreview(docUrl) {
    if (docUrl) {
      const filename = encodeURIComponent(docUrl);
      const token = localStorage.getItem('token');

      http(`user/origin-pdf-uploads/${filename}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then(response => {
          if (response.ok) {
            return response.blob();
          } else {
            console.error(`Error: Server responded with status: ${response.status}`);
            throw new Error(`Error: ${response.statusText}`);
          }
        })
        .then(blob => {
          const blobUrl = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = blobUrl;
          link.download = filename;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(blobUrl);
        })
        .catch(error => {
          console.error('Error:', error);
        });
    } else {
      alert("Aucun CV n'a été déposé.");
    }
  }

  const toggleAnonymous = (cvId) => {
    const cvToUpdate = userCVs.find((cv) => cv.id === cvId);
    if (!cvToUpdate) return; // Safety check

    const newAnonymousState = !cvToUpdate.anonymous;

    http(`cv/${cvId}/anonymous`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ anonymous: newAnonymousState }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setUserCVs((prevCVs) => {
            const updatedCVs = prevCVs.map((cv) => {
              if (cv.id === cvId) {
                return { ...cv, anonymous: newAnonymousState };
              }
              return cv;
            });
            return updatedCVs;
          });
        }
      })
      .catch((error) => console.error(error));
  };

  function formatPrenom(prenom) {
    if (!prenom) return "";

    return prenom
      .split(/[-\s]/)
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(prenom.includes("-") ? "-" : " ");
  }

  const createCv = (event) => {
    if (event) {
      event.preventDefault();
    }

    addCv()
      .then(response => response.json())
      .then(data => {
        history.push(`/mon-espace-cv/${user.id}/${data.id}`);
      })
      .catch(error => console.error(error));

  };

  return (
    <MDBContainer fluid className={isDarkMode ? "bg-dark-mode" : "bg-light"}>
      <MDBContainer className="pb-5">
        <section className="text-center text-md-start bg-nav animation-fade-in">
          <div
            className="p-5 bg-image"
            style={{
              height: "300px",
              backgroundImage: 'url("./bg/creation-cv.jpg")',
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundAttachment: "fixed",
            }}
          ></div>
          <div
            className="pb-2"
            style={{ backgroundColor: "background-color: hsl(0, 0%, 98%)" }}
          >
            <MDBContainer>
              <MDBRow className="d-flex justify-content-center align-items-center">
                <MDBCol lg="7" md="7" className="mb-4 mb-md-0 pt-4">
                  <div className="image-container">
                    <img
                      src="./spinner-alcyone.png"
                      className="rounded-circle float-none float-md-start me-4 mb-3 bg-white"
                      alt=""
                      style={{
                        width: "168px",
                        marginTop: "-80px",
                        border: "4px solid hsl(0, 0%, 98%)",
                        position: "relative",
                        zIndex: 1000,
                      }}
                    />
                  </div>
                  <h1 className={isDarkMode ? "text-white" : "text-muted"}>
                    {user && user.prenom ? formatPrenom(user.prenom) : ""}{" "}
                    <span className="text-uppercase">
                      {user && user.nom ? user.nom : ""}
                    </span>
                  </h1>
                  <h2 style={{ color: "#09B4BF" }}>{user.role === "SUPERADMIN" ? "SUPER ADMIN" : user.role}</h2>
                </MDBCol>
                <MDBCol
                  lg="5"
                  md="5"
                  className="mb-4 mb-md-0 text-center text-lg-end"
                >
                  {user && user.manager && (
                    <div className="d-flex align-items-center justify-content-end">
                      <div className="d-flex flex-column">
                        {" "}
                        <h3
                          className={
                            isDarkMode
                              ? "text-white"
                              : "text-muted d-flex align-items-center"
                          }
                        >
                          {" "}
                          {formatPrenom(user.manager.prenom)}
                          <span className="text-uppercase ms-2">
                            {user.manager.nom}
                          </span>
                        </h3>
                        <span style={{ color: "#09B4BF" }}>
                          {user.manager?.managerDetails?.poste}
                        </span>
                      </div>
                      <div className="image-container mt-3 ms-3">
                        <img
                          src="./spinner-alcyone.png"
                          className="rounded-circle float-none float-md-start me-4 mb-3 bg-white"
                          alt=""
                          style={{
                            width: "112px",
                            border: "4px solid hsl(0, 0%, 98%)",
                          }}
                        />
                      </div>
                    </div>
                  )}
                  {user.role === "SUPERADMIN" && (
                    <Link to="/super-admin">
                      <MDBBadge className="mx-2" color="info" light>
                        Espace Super Admin
                      </MDBBadge>
                      <MDBIcon
                        fas
                        icon="user-secret"
                        size="3x"
                        style={{ color: "#09B4BF" }}
                        className=""
                      />
                      <MDBBadge color="danger" dot />
                    </Link>
                  )}
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </div>
        </section>

        <section className="mt-5">
          <div>
            <ul
              className="nav nav-tabs mb-5 justify-content-center"
              id="issuesTabs"
              role="tablist"
            >
              {user.role !== "CLIENT" && (
                <li className="nav-item" role="Mes CVS">
                  <button
                    className={`nav-link ${activeTab === "Mes CVS" ? "active" : ""
                      } ${isDarkMode ? "text-white bg-dark-mode" : ""}`}
                    id="tab-3"
                    onClick={() => setActiveTab("Mes CVS")}
                  >
                    Mes CVs
                  </button>
                </li>
              )}
              {user.role !== "CLIENT" && (
                <li className="nav-item" role="cvOrigine">
                  <button
                    className={`nav-link ${activeTab === "cvOrigine" ? "active" : ""
                      } ${isDarkMode ? "text-white bg-dark-mode" : ""}`}
                    id="tab-2"
                    onClick={() => setActiveTab("cvOrigine")}
                  >
                    CV d'origine
                  </button>
                </li>
              )}
              {user.role !== "CLIENT" && (
                <li className="nav-item" role="Evaluation">
                  <button
                    className={`nav-link ${activeTab === "Evaluation" ? "active" : ""
                      } ${isDarkMode ? "text-white bg-dark-mode" : ""}`}
                    id="tab-1"
                    onClick={() => setActiveTab("Evaluation")}
                  >
                    Evaluation des performances
                  </button>
                </li>
              )}
              <li className="nav-item" role="Information">
                <button
                  className={`nav-link ${activeTab === "Information" ? "active" : ""
                    } ${isDarkMode ? "text-white bg-dark-mode" : ""}`}
                  id="tab-1"
                  onClick={() => setActiveTab("Information")}
                >
                  Mes Informations
                </button>
              </li>
            </ul>
          </div>
          {user.role !== "CLIENT" && activeTab === "Evaluation" && (
            <div>
              <h2
                className={`text-center py-2 ${isDarkMode ? "text-white" : ""}`}
              >
                Evaluation des performances
              </h2>
              <div className="row">
                <div className="col-12 col-md-6 py-5">
                  <CircularProgressBar />
                </div>

                <div className="col-12 col-md-6 shadow-4 rounded-5 mt-4">
                  <div className="d-flex justify-content-around">
                    <p className="pt-2" style={{ color: "#09B4BF" }}>
                      Client :
                    </p>
                    <p className="pt-2" style={{ color: "#09B4BF" }}>
                      Période :
                    </p>
                  </div>
                  <MDBTable>
                    <MDBTableHead
                      className={`${isDarkMode ? "bg-dark-mode" : ""}`}
                    >
                      <tr style={{ color: "#09B4BF" }}>
                        <th className="">Evaluation</th>
                        <th className="">Note Correspondante</th>
                      </tr>
                    </MDBTableHead>
                    <MDBTableBody style={{ verticalAlign: "middle" }}>
                      <tr className={isDarkMode ? "text-white" : "text-muted"}>
                        <td>
                          <div>
                            <p>Ponctualité</p>
                          </div>
                        </td>
                        <td>
                          <div>
                            <p>10</p>
                          </div>
                        </td>
                      </tr>
                      <tr className={isDarkMode ? "text-white" : "text-muted"}>
                        <td>
                          <div>
                            <p>Connaissances et compétences professionnelles</p>
                          </div>
                        </td>
                        <td>
                          <div>
                            <p>10</p>
                          </div>
                        </td>
                      </tr>
                      <tr className={isDarkMode ? "text-white" : "text-muted"}>
                        <td>
                          <div>
                            <p>Sens du collectif et coopération</p>
                          </div>
                        </td>
                        <td>
                          <div>
                            <p>10</p>
                          </div>
                        </td>
                      </tr>
                      <tr className={isDarkMode ? "text-white" : "text-muted"}>
                        <td>
                          <div>
                            <p>Capacité de résolution de problèmes</p>
                          </div>
                        </td>
                        <td>
                          <div>
                            <p>10</p>
                          </div>
                        </td>
                      </tr>
                    </MDBTableBody>
                  </MDBTable>
                </div>
              </div>
            </div>
          )}
        </section>
        {user.role !== "CLIENT" && activeTab === "cvOrigine" && (
          <div>
            <div className="d-flex justify-content-between mb-3" style={{ cursor: "pointer" }}>
              <div></div>
              <div className="d-flex align-items-center">
                <MDBBadge className="mx-2" color="info" light onClick={() => {
                  setPopupVisible(true);
                }}>
                  Déposer PDF
                </MDBBadge>
                <MDBIcon
                  fas
                  icon="plus-circle"
                  size="2x"
                  style={{ color: "#09B4BF" }}
                  onClick={() => setPopupVisible(true)}
                />
              </div>
            </div>
            <div className="">
              {isPopupVisible && (
                <div className="popup">
                  <div className="popup-content text-center">
                    <MDBBtn onClick={() => setPopupVisible(false)}>x</MDBBtn>
                    {(currentUser.role === "MANAGER" || currentUser.role === "SUPERADMIN") ? (
                      <div className="m-auto w-50">
                        <PDFViewer
                          pdfFile={pdfFile}
                          setPdfFile={setPdfFile}
                          currentUser={currentUser}
                          overrideRoleBehavior={true}
                        />
                        <div>
                          <CustomPDFDisplay
                            pdfFile={pdfFile}
                            currentUser={currentUser}
                            overrideRoleBehavior={true}
                          />
                        </div>
                      </div>
                    ) : (
                      <div className="text-center">
                        <PDFViewer
                          pdfFile={pdfFile}
                          setPdfFile={setPdfFile}
                          currentUser={currentUser}
                        />
                        <div>
                          <CustomPDFDisplay
                            pdfFile={pdfFile}
                            currentUser={currentUser}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
            <div
              className="shadow-4 rounded-5 table-container"
              style={{ overflowX: "auto" }}
            >
              <MDBTable>
                <MDBTableHead className={`${isDarkMode ? "bg-dark-mode" : ""}`}>
                  <tr style={{ color: "#09B4BF" }}>
                    <th>Nom et prénom</th>
                    <th className="text-center">Nom du fichier</th>
                    <th className="text-center">Fichier</th>
                  </tr>
                </MDBTableHead>
                <MDBTableBody style={{ verticalAlign: "middle" }}>
                  <tr className={isDarkMode ? "text-white" : "text-muted"}>
                    <td>
                      <div className="d-flex align-items-center">
                        <img
                          src="./spinner-alcyone.png"
                          alt=""
                          style={{ width: "45px", height: "45px" }}
                          className="rounded-circle"
                        />
                        <p className="fw-bold mb-1">
                          <span className="text-uppercase">{user.nom}</span>{" "}
                          {formatPrenom(user.prenom)}
                        </p>
                      </div>
                    </td>
                    <td className="text-center">
                      {user.docUrl ? (
                        user.docUrl.split('\\').pop()
                      ) : (
                        "Aucun fichier"
                      )}
                    </td>
                    <td className="text-center">
                      <p className="mb-0">
                        {user.docUrl ? (
                          <MDBIcon
                            far icon="file-pdf"
                            size="2x"
                            style={{ color: "#09B4BF", cursor: "pointer" }}
                            onClick={() => handleOriginPdfPreview(user.docUrl)}
                            title="Ouvrir le PDF"
                          />
                        ) : (
                          "Aucun CV n'a été déposé."
                        )}
                      </p>
                    </td>
                  </tr>
                </MDBTableBody>
              </MDBTable>
            </div>
          </div>
        )}
        {activeTab === "Information" && (
          <>
            <Information userData={user} authenticatedUserRole={currentUser.role} />
          </>
        )}
        {user.role !== "CLIENT" && activeTab === "Mes CVS" && (
          <div>
            <div className="d-flex justify-content-between mb-3">
              <div></div>
              <div className="d-flex align-items-center" style={{ cursor: "pointer" }}>
                <MDBBadge
                  className="mx-2 ms-5"
                  color="info"
                  light
                  onClick={createCv}
                >
                  Créer un CV
                </MDBBadge>
                <div onClick={createCv}>
                  <MDBIcon
                    fas
                    icon="plus-circle"
                    size="2x"
                    style={{ color: "#09B4BF" }}
                  />
                </div>
              </div>
            </div>
            <div
              className="shadow-4 rounded-5 mt-2 table-container"
              style={{ overflowX: "auto" }}
            >
              <div className="d-flex align-items-center justify-content-center">
                <h2
                  className={`text-center p-2 ${isDarkMode ? "text-white" : ""
                    }`}
                >
                  Mes CVs
                </h2>
              </div>
              <MDBTable>
                <MDBTableHead className={`${isDarkMode ? "bg-dark-mode" : ""}`}>
                  <tr style={{ color: "#09B4BF" }}>
                    <th>
                      <span title="Ceci est le titre d'un CV">
                        Nom du CV
                        <i className="fas fa-info-circle ms-1"></i>
                      </span>
                    </th>
                    <th>Anonyme</th>
                    <th>Mot clé</th>
                    <th>Langue</th>
                    <th>Date de création</th>
                    <th>Date de modification</th>
                    <th>Statut</th>
                    <th className="text-center">Actions</th>
                  </tr>
                </MDBTableHead>
                <MDBTableBody style={{ verticalAlign: "middle" }}>
                  {sortedCVs.map((cv, index) => {
                    const mot_cle = cv.mot_cle;

                    return (
                      <tr
                        key={index}
                        className={isDarkMode ? "text-white" : "text-muted"}
                      >
                        <td>
                          <div className="d-flex align-items-center">
                            <img
                              src="./spinner-alcyone.png"
                              alt=""
                              style={{ width: "45px", height: "45px" }}
                              className="rounded-circle"
                            />
                            <Link
                              to={`/mon-espace-cv/${user.id}/${cv.id}`}
                              style={{ cursor: "pointer" }}
                            >
                              <MDBBadge color="info" light>
                                ACO_{user.prenom ? user.prenom : "-"}_
                                <span className="text-uppercase">
                                  {user.nom ? user.nom : "-"}
                                </span>
                                _CV_
                                <span className="text-uppercase">
                                  {cv.langues ? cv.langues : "-"}
                                </span>
                              </MDBBadge>
                            </Link>
                          </div>
                        </td>
                        <td>
                          <div className="text-center">
                            {cv.anonymous ? (
                              <MDBIcon
                                fas
                                icon="user-secret"
                                size="lg"
                                style={{ color: "#09B4BF" }}
                                title="oui"
                              />
                            ) : (
                              <MDBIcon
                                fas
                                icon="user-alt"
                                size="lg"
                                style={{ color: "#09B4BF" }}
                                title="non"
                              />
                            )}
                          </div>

                        </td>
                        <td>
                          <p className="mb-0">{cv.mot_cle}</p>
                        </td>
                        <td>
                          <p className="fw-normal mb-1 text-uppercase">
                            {cv.langues}
                          </p>
                        </td>
                        <td>
                          <p className="fw-normal mb-1">
                            {new Date(cv.date_creation).toLocaleDateString(
                              "fr-FR"
                            )}
                          </p>
                        </td>
                        <td>
                          <p className="fw-normal mb-1">
                            {cv.date_modification && cv.date_modification !== 0
                              ? new Date(cv.date_modification).toLocaleDateString(
                                "fr-FR"
                              )
                              : "-"}
                          </p>
                        </td>
                        <td>
                          <MDBBadge
                            light
                            color={(() => {
                              switch (cv.statut) {
                                case "VALIDE":
                                  return "success";
                                case "BROUILLON":
                                  return "warning";
                                case "NON_COMMENCE":
                                  return "danger";
                                default:
                                  return "secondary";
                              }
                            })()}
                            pill
                          >
                            {cv.statut}
                          </MDBBadge>
                        </td>

                        <td>
                          <div className="d-flex">
                            <Link to={`/mon-espace-cv/${user.id}/${cv.id}`}>
                              <MDBBtn
                                className="fw-bold"
                                color="link"
                                rounded
                                size="sm"
                                rippleColor="dark"
                                title="Éditer le CV"
                              >
                                <MDBIcon fas icon="edit" />
                              </MDBBtn>
                            </Link>
                            <div style={{ position: "relative" }}>
                              <MDBBtn
                                className="fw-bold"
                                color="link"
                                rounded 
                                size="sm"
                                rippleColor="dark"
                                title="Copier lien"
                                onClick={() => handleCopyPdfLink(user, cv.id, mot_cle)}
                              >
                                <MDBIcon far icon="clone" />
                              </MDBBtn>
                              {showBadge[cv.id] && (
                                <MDBBadge
                                  color="info"
                                  className="position-absolute"
                                  style={{ top: "10px", right: "10px" }}
                                >
                                  Lien copié !
                                </MDBBadge>
                              )}
                            </div>
                            <MDBBtn
                              className="fw-bold"
                              color="link"
                              rounded
                              size="sm"
                              rippleColor="dark"
                              onClick={() => handleClone(cv.id)}
                              title="Cloner"
                            >
                              <MDBIcon far icon="copy" />
                            </MDBBtn>
                          </div>
                          <MDBBtn
                            className="fw-bold"
                            color="link"
                            rounded
                            size="sm"
                            rippleColor="dark"
                            title="Effacer"
                            onClick={() => {
                              handleDeleteCv(cv.id);
                            }}

                          >
                            <MDBIcon far icon="trash-alt" />
                          </MDBBtn>
                          <MDBBtn
                            className="fw-bold"
                            color="link"
                            rounded
                            size="sm"
                            rippleColor="dark"
                            title="Partager"
                            onClick={() => handleEmailShare(user, cv.id, mot_cle)}
                          >
                            <MDBIcon fas icon="share-square" />
                          </MDBBtn>
                          <MDBBtn
                            className="fw-bold"
                            color="link"
                            rounded
                            size="sm"
                            rippleColor="dark"
                            title="Prévisualiser"
                            onClick={() => handlePdfPreview(user, cv.id, mot_cle)}
                          >
                            <MDBIcon far icon="eye" />
                          </MDBBtn>
                        </td>
                      </tr>
                    );
                  })}
                </MDBTableBody>
              </MDBTable>
            </div>
          </div>
        )}

      </MDBContainer>
    </MDBContainer >
  );
}
