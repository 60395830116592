import { method } from "lodash";

const baseUrl = process.env.REACT_APP_API_BASE_URL;

//localhost:8080 :
export const http = (url, options = {}) => {
  options.mode = 'cors';
  const token = localStorage.getItem("token");

  if (!options.headers) {
    options.headers = {
      'Content-Type': 'application/json'
    }
  }
  if (token) {
    if (options.headers) {
      options.headers.Authorization = `Bearer ${token}`;
    }
  }

  console.log(options)
  return fetch(baseUrl + url, options)
};

export const updateCV= (userId, cvId, content) => {
  return http(`cv/update/${userId}/${cvId}`,{
    method: 'PUT',
    body: JSON.stringify(content)
  });
}

//Accueil, CreationCv
export const getProfile = () => {
  return http("user/profile", {
    method: "GET",
  });
};


export const fetchManagers = async () => {
  const response = await http("user/managers", {
    method: "GET",
  });

  if (response.ok) {
    return response.json();
  } else {
    throw new Error("Error fetching managers");
  }
};

// Accueil 
export const authenticateUser = (body) => {
  return http("api/v1/auth/authenticate", {
    method: "POST",
    body: JSON.stringify(body)
  });
};

// Accueil et SuperAdmin
export const getUploadedFiles = () => {
  const token = localStorage.getItem("token");
  return http("clients/uploads", {
    method: "GET",
  });
};

export const deleteImage = (filename) => {

  return http(`clients/upload/${filename}`, {
    method: "DELETE"
  });
};

// insription
export const registerUser = (body) => {
  return http("api/v1/auth/register", {
    method: "POST",
    body: JSON.stringify(body)
  });
};

export const forgotPassword = (email) => {
  return http("api/v1/auth/forgot-password", {
    method: "POST",
    body: JSON.stringify({ email })
  });
};

// Candidats.jsx
export const getAllUsersWithCVs = () => {
  return http('user/all-with-cvs', {
    method: "GET"
  });
};

export const downloadUserPdf = (userId) => {
  return http(`user/download-pdf/${userId}`, {
    method: "GET"
  });
};

//Candidats.jsx, CreationCv.jsx, CreationCvSm;jsx
export const getAllUsers = () => {
  return http('user/all', {
    method: "GET"
  });
};

export const getAllTempUsers = () => {
  return http('user-temp/all', {
    method: "GET"
  });
};

export const deleteTempUserById = (userId) => {
  return http(`user-temp/delete/${userId}`, {
    method: "DELETE"
  })
}

export const addCv = () => {
  const url = "cv/add";

  return http(url, {
    method: "POST"
  });
};

//CvDetail
export const getUserById = (userId) => {
  return http(`user/find/${userId}`, {
    method: "GET"
  });
};

export const getUserWithCvsById = (userId) => {
  return http(`user/find-with-cvs/${userId}`, {
    method: "GET"
  });
};

//SuperAdmin
export const uploadImage = (file) => {
  const formData = new FormData();
  formData.append('file', file);

  return http("clients/upload", {
    method: 'POST',
    body: formData
  });
};


