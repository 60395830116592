import React, { useState } from 'react';
import { useHistory, useParams, Link } from 'react-router-dom';
import { MDBContainer, MDBRow, MDBCol, MDBInput, MDBBtn, MDBBadge, MDBTextArea, } from "mdb-react-ui-kit";
import useAuth from '../../components/SSO/useAuth';
import { http } from '../../components/services/httpService';
import { toast } from 'react-toastify';

export default function CreatePasswordClient() {
    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [passwordConfirm, setPasswordConfirm] = useState("");
    const [error, setError] = useState("");
    const history = useHistory();
    const auth = useAuth();
    const [dataDuration, setDataDuration] = useState(4);
    // Extraire le token à partir du paramètre de requête (URL)
    const { token } = useParams();
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [tokenError, setTokenError] = useState("");
    const [nom, setNom] = useState('');
    const [message, setMessage] = useState('');

    const validatePassword = (password) => {
        const regex = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W).{12,}/;
        return regex.test(password);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (password !== passwordConfirm) {
            setError("Les mots de passe ne correspondent pas.");
            return;
        }

        if (!validatePassword(password)) {
            setError("Le mot de passe doit avoir au moins 12 caractères, contenant des majuscules, des minuscules, des chiffres et un caractère spécial.");
            return;
        }

        try {
            const response = await http("api/v1/auth/set-password-client", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ firstname, lastname, email, password, token, dataDuration }),
            });

            if (!response.ok) {
                const errorMessage = await response.text();
                if (errorMessage === 'Token invalide ou expiré') {
                    setTokenError(errorMessage);
                    toast.error(errorMessage || "Token invalide ou expiré'");
                } else {
                    setError(errorMessage || "Le mot de passe ne correspond pas au format demandé");
                    toast.error(errorMessage);
                }
                return;
            }

            toast.success("Inscription réussie! Veuillez vous connecter pour accéder à votre compte.");
            auth.displayLogin();

        } catch (error) {
            console.error(error);
            toast.error(error);
        }
    };

    const [showPassword, setShowPassword] = useState(false);
    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleRequestNewLink = async (event) => {
        event.preventDefault();
        try {
            const response = await http('send-email', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    to: email,
                    subject: `Demande d'un nouveau lien pour ${nom}`,
                    content: message
                }),
            });

            if (response.ok) {
                toast.success("Message envoyé avec succès");
            } else {
                throw new Error('Erreur lors de la demande d\'un nouveau lien.');
            }
        } catch (error) {
            console.error(error);
            toast.error("Erreur lors de l'envoi du message");
        }
    };

    return (
        <MDBContainer fluid className="fond-accueil inscription pt-5">
            <MDBRow className="d-flex justify-content-center align-items-center"
                style={{ minHeight: "90VH" }}>
                <MDBCol md="6" className="form-card">
                    <form onSubmit={handleSubmit}>
                        <p className="h5 text-center mb-4">Créez votre mot de passe</p>
                        <div className="grey-text">
                            <div className='pb-2'>
                                <MDBInput
                                    label="Prénom"
                                    type="text"
                                    value={firstname}
                                    onChange={(e) => setFirstname(e.target.value)}
                                    required
                                />
                                <small className="text-white fst-italic" style={{ fontSize: "11px" }} >
                                    *Veuillez utiliser le même prénom que celui du lien reçu.
                                </small>
                            </div>
                            <div className='pb-2'>
                                <MDBInput
                                    label="Nom"
                                    type="text"
                                    value={lastname}
                                    onChange={(e) => setLastname(e.target.value)}
                                    required
                                />
                                <small className="text-white fst-italic" style={{ fontSize: "11px" }}>
                                    *Veuillez utiliser le même nom que celui que vous avez donné au manager.
                                </small>
                            </div>
                            <div className='pb-2'>
                                <MDBInput
                                    label="E-mail"
                                    type="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                />
                                <small className="text-white fst-italic" style={{ fontSize: "11px" }}>
                                    *Veuillez utiliser le même e-mail que celui sur lequel vous avez reçu l'invitation.
                                </small>
                            </div>
                            <div className='position-relative'>
                                <MDBInput
                                    label="Mot de passe"
                                    type={showPassword ? "text" : "password"}
                                    value={password}
                                    onChange={(e) => {
                                        setPassword(e.target.value);
                                        if (error) setError("");
                                    }}
                                    required
                                    className="mb-4"
                                />
                                <i
                                    className={`fas fa-eye${showPassword ? "-slash" : ""
                                        } password-toggle-icon`}
                                    onClick={toggleShowPassword}
                                    style={{
                                        position: "absolute",
                                        top: "28%",
                                        right: "22px",
                                        cursor: "pointer",
                                        color: "white",
                                    }}
                                ></i>
                            </div>
                            <div className='position-relative'>
                                <MDBInput
                                    label="Confirmer le mot de passe"
                                    type={showPassword ? "text" : "password"}
                                    value={passwordConfirm}
                                    onChange={(e) => {
                                        setPasswordConfirm(e.target.value);
                                        if (error) setError("");
                                    }}
                                    required
                                />
                                <i
                                    className={`fas fa-eye${showPassword ? "-slash" : ""
                                        } password-toggle-icon`}
                                    onClick={toggleShowPassword}
                                    style={{
                                        position: "absolute",
                                        top: "28%",
                                        right: "22px",
                                        cursor: "pointer",
                                        color: "white",
                                    }}
                                ></i>
                            </div>
                            {error && <MDBBadge color='danger' light className="error-message">{error}</MDBBadge>}
                            <div className="text-white pt-2">
                                <h5>Besoin d'aide ?</h5>
                                <p>Pour choisir un mot de passe sécurisé :</p>
                                <ul>
                                    <li>Utilisez au moins 12 caractères.</li>
                                    <li>Mélangez majuscules et minuscules.</li>
                                    <li>Utilisez au moins un chiffre.</li>
                                    <li>Utilisez des caractères spéciaux comme !, @, #, $, etc.</li>
                                </ul>
                            </div>
                            <p>
                                En cliquant sur le bouton "Créer mon compte", je fournis librement mes données personnelles et donne mon consentement à Alcyone Consulting pour les utiliser pendant une durée 4 mois afin de répondre à une annonce de recrutement, dans le cadre de ma candidature spontanée, et/ou pour proposer mon CV à ses clients et prospects. A défaut d'avoir cliqué sur "Réserve de recrutement" ci-dessous, mes données seront automatiquement effacées de la base de données d'Alcyone Consulting dans 4 mois. En respect à la réglementation RGPD, je conserve la possibilité de retirer mon consentement à tout moment.
                            </p>
                            <div className='text-white form-check'>
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value="18"
                                    id="defaultCheck1"
                                    onChange={(e) => {
                                        setDataDuration(e.target.checked ? 18 : 4);
                                    }}
                                />
                                <label className="form-check-label" htmlFor="defaultCheck1">
                                    Réserve de recrutement :
                                    <span className='ms-2'>j'autorise Alcyone Consulting à conserver et utiliser les données liées à ma candidature au delà de 4 mois et pendant 18 mois maximum afin de maximiser mes opportunités d'évolution de carrière. A défaut de renouvellement de mon consentement écrit avant la fin de cette période de rétention, mes données seront automatiquement effacées de la base de données d'Alcyone Consulting dans 18 mois.</span>
                                </label>
                            </div>
                            <div className='text-white form-check mt-3'>
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="defaultCheck2"
                                    onChange={(e) => setTermsAccepted(e.target.checked)}
                                    required
                                />
                                <label className="form-check-label" htmlFor="defaultCheck2">
                                    J'ai lu et j'accepte les conditions générales et la politique de confidentialité
                                </label>
                                <div style={{ marginLeft: "50px" }}>
                                    <ul className='text-white'>
                                        <li>
                                            <Link to="/mentions-legales" target="_blank">
                                                <span className='text-white'>
                                                    Mentions légales
                                                </span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/politique" target="_blank">
                                                <span className='text-white'>
                                                    Politique de confidentialité
                                                </span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/cgu" target="_blank">
                                                <span className='text-white'>
                                                    CGU
                                                </span>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="text-center">
                            <MDBBtn className='BtnCard' type="submit" disabled={!termsAccepted}>Créer mon compte</MDBBtn>
                        </div>
                        {tokenError === 'Token invalide ou expiré' && (
                            <div className="text-center mt-3">
                                <p className='fw-bold'>DEMANDER UN NOUVEAU LIEN</p>
                                <form>
                                    <MDBInput className='mb-4' type='email' name='email' label='Email de votre manager' value={email} onChange={(e) => setEmail(e.target.value)} />
                                    <MDBInput className='mb-4' name='nom' label='Votre nom et prénom' value={nom} onChange={(e) => setNom(e.target.value)} />
                                    <MDBTextArea label='Message' rows={4} className='mb-4' name='message' value={message} onChange={(e) => setMessage(e.target.value)} />
                                    <MDBBtn type='button' block className='BtnCard mb-4' onClick={handleRequestNewLink}>
                                        envoyer
                                    </MDBBtn>
                                </form>
                            </div>
                        )}
                    </form>
                </MDBCol>
            </MDBRow>
        </MDBContainer>
    );

}
