import React from "react";
import { useEffect, useState } from "react";
import "../../Pages/Accueil/Accueil.css";
import { useTranslations } from "../../components/hooks/useTranslations";
import { Switch } from "@mui/material";
import { toast } from 'react-toastify';
import SelectLanguage from "../EditionCv/SelectLanguage";
import { languageMap } from "../../components/assets/languageMap";
import {
  MDBContainer,
  MDBRow,
  MDBInputGroup,
  MDBIcon,
  MDBTable,
  MDBTableBody,
  MDBBtn,
  MDBBadge,
} from "mdb-react-ui-kit";
import { getAllUsers } from "../../components/services/httpService";
import { useDarkMode } from "../../components/assets/DarkModeContext";
import { http } from "../../components/services/httpService";

export default function RechercheAvance() {
  const [users, setUsers] = useState([]);
  const [search, setSearch] = useState("");
  const { isDarkMode } = useDarkMode();
  const [searchByLanguage, setSearchByLanguage] = useState('');
  const [searchByCompetence, setSearchByCompetence] = useState('');
  const { translations, updateTranslations } = useTranslations();
  const [showElements, setShowElements] = useState(false);
  const [chips, setChips] = useState([]);
  const [competences, setCompetences] = useState([]);
  const [filteredCompetences, setFilteredCompetences] = useState([]);
  const [managerId, setManagerId] = useState(null);
  const [managers, setManagers] = useState([]);
  const [selectedSector, setSelectedSector] = useState('');
  const [selectedRole, setSelectedRole] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('');
  const [minYears, setMinYears] = useState(0);
  const [maxYears, setMaxYears] = useState(99);
  const [minFormationLevel, setMinFormationLevel] = useState('');
  const [maxFormationLevel, setMaxFormationLevel] = useState('');
  const [selectedLanguage, setSelectedLanguage] = useState('FR');
  const formationLevelsOrder = {
    "": 0,
    "BAC": 1,
    "BAC1": 2,
    "BAC2": 3,
    "BAC3": 4,
    "BAC4": 5,
    "BAC5": 6,
    "BAC67": 7,
    "BAC8": 8,
  };
  const [minLanguageLevel, setMinLanguageLevel] = useState('');
  const [maxLanguageLevel, setMaxLanguageLevel] = useState('');
  const languageLevelsOrder = {
    "": 0,
    "motherTongue": 6,
    "A1": 1,
    "A2": 2,
    "B1": 3,
    "B2": 4,
    "C1": 5,
    "C2": 6,
  };

  const [setSearchQuery] = useState('');
  const [allMetiers, setAllMetiers] = useState([]);
  const [filteredMetiers, setFilteredMetiers] = useState([]);
  const [selectedMetier, setSelectedMetier] = useState('');

  const handleSearchChange = (event) => {
    const newQuery = event.target.value.trim().toLowerCase();
    console.log("Updating search query to:", newQuery);
    setSearchQuery(newQuery);
  };

  const handleMinLanguageChange = (e) => {
    setMinLanguageLevel(e.target.value);
  };

  const handleMaxLanguageChange = (e) => {
    setMaxLanguageLevel(e.target.value);
  };

  const calculateExperienceDuration = (experiences) => {
    //filtre les expériences pour garder qui ont une date de début non null
    const validExperiences = experiences.filter(exp => exp.dateDebut != null);
    //calcule la différence en mois entre deux dates
    const getMonthDifference = (startDate, endDate) => {
      //convertit les dates en objet Date
      const start = new Date(startDate);
      //si est nulle, elle utilise la date actuelle
      const end = endDate ? new Date(endDate) : new Date();
      // calcule la différence en années
      const yearDiff = end.getFullYear() - start.getFullYear();
      //en mois
      const monthDiff = end.getMonth() - start.getMonth();
      //retourne le nombre total de mois en ajoutant un mois si le jour de la date de fin est supérieur ou égal au jour de la date de début
      return yearDiff * 12 + monthDiff + (end.getDate() >= start.getDate() ? 1 : 0);
    };
    //pour accumuler la durée totale en mois de toutes les expériences  / on calcule la différence en mois ou la date actuelle si dateFin est null
    const totalMonths = validExperiences.reduce((sum, exp) => sum + getMonthDifference(exp.dateDebut, exp.dateFin || new Date()), 0);
    //divise le nombre total de mois par 12 pour obtenir la durée en années
    return totalMonths / 12;
  };

  const calculateCompetenceSpecificDuration = (experiences, competenceName) => {
    if (!experiences || experiences.length === 0) {
      console.error("No experiences provided to calculateCompetenceSpecificDuration");
      return 0;
    }

    const validExperiences = experiences.filter(exp =>
      exp.envTechnique && JSON.parse(exp.envTechnique) && JSON.parse(exp.envTechnique).includes(competenceName) && exp.dateDebut != null
    );

    const getMonthDifference = (startDate, endDate) => {
      const start = new Date(startDate);
      const end = endDate ? new Date(endDate) : new Date();
      const yearDiff = end.getFullYear() - start.getFullYear();
      const monthDiff = end.getMonth() - start.getMonth();
      return yearDiff * 12 + monthDiff + (end.getDate() >= start.getDate() ? 1 : 0);
    };

    const totalMonths = validExperiences.reduce((sum, exp) => {
      return sum + getMonthDifference(exp.dateDebut, exp.dateFin);
    }, 0);
    console.log(`Total months for competence ${competenceName}:`, totalMonths);
    return totalMonths / 12;
  };

  //vérifie si l'utilisateur a des cvs et si le premier CV contient des expériences
  const matchesExperienceLevel = (user, minYears, maxYears) => {
    if (!user.cvs || user.cvs.length === 0 || !user.cvs[0].experiences) {
      return false;
    }
    //calcule la durée totale d'expérience en années à partir des expériences du premier CV de l'utilisateur
    const experienceYears = calculateExperienceDuration(user.cvs[0].experiences);
    //compare la durée d'expérience avec les années min et max de mon intervalle 0 a 99
    return experienceYears >= minYears && experienceYears <= maxYears;
  };

  const handleManagerChange = (event) => {
    const managerId = event.target.value;
    setManagerId(managerId);

    if (managerId) {
      const manager = managers.find(m => m.id === parseInt(managerId));
      if (manager) {
        const newChip = { type: 'manager', label: `${manager.prenom} ${manager.nom}`, id: manager.id };
        setChips(chips => chips.filter(chip => chip.type !== 'manager').concat(newChip));
      }
    } else {
      setChips(chips => chips.filter(chip => chip.type !== 'manager'));
    }
  };

  useEffect(() => {
    const fetchCompetences = async () => {
      try {
        const response = await http('competences/all');
        const data = await response.json();
        setCompetences(data);
      } catch (error) {
      }
    };

    fetchCompetences();
  }, []);

  const requestOptions = {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    }
  };

  const fetchTranslatedCompetences = async (languageCode) => {
    try {
      toast.info("Chargement en cours, cela peut prendre quelques secondes...", { autoClose: false });

      const updatedCompetences = await Promise.all(competences.map(async (competence) => {
        const response = await http(`competences/translate/${competence.id}/${languageCode}`, requestOptions);
        if (!response.ok) {
          throw new Error(`Error: ${response.status}`);
        }
        const translatedCompetence = await response.json();
        return { ...competence, ...translatedCompetence };
      }));
      setCompetences(updatedCompetences);
    } catch (error) {
      console.error("Erreur:", error);
    } finally {
      toast.dismiss();
    }
  };

  const handleSelectLanguageChange = (event) => {
    const newLanguage = event.target.checked ? 'EN' : 'FR';
    if (newLanguage !== selectedLanguage) {
      setSelectedLanguage(newLanguage);
      fetchTranslatedCompetences(newLanguage);
      updateTranslations(newLanguage);
    }
  };

  const handleButtonClick = () => {
    setShowElements(!showElements);
  };

  const normalizeLanguageForSearch = (language) => {
    let normalizedLanguage = languageMap[language];

    if (!normalizedLanguage) {
      const lowerCaseLanguage = language.toLowerCase();
      normalizedLanguage = Object.keys(languageMap)
        .find(key => key.toLowerCase() === lowerCaseLanguage || languageMap[key].toLowerCase() === lowerCaseLanguage);

      normalizedLanguage = normalizedLanguage ? languageMap[normalizedLanguage] : language;
    }

    return normalizedLanguage;
  };

  const handleLanguageChange = (e) => {
    const selectedLanguage = e.target.value;
    const normalizedLanguage = normalizeLanguageForSearch(selectedLanguage);

    if (normalizedLanguage && !chips.some(chip => normalizeLanguageForSearch(chip.label) === normalizedLanguage && chip.type === 'language')) {
      setChips([...chips, { type: 'language', label: normalizedLanguage }]);
    }
    setSearchByLanguage('');
  };

  const handleCompetenceChange = (e) => {
    const searchText = e.target.value.toLowerCase();
    setSearchByCompetence(searchText);

    const filtered = competences.filter(comp =>
      comp.competenceName?.toLowerCase().includes(searchText)
    );
    setFilteredCompetences(filtered);
  };

  const handleMinFormationChange = (e) => {
    setMinFormationLevel(e.target.value.toLowerCase());
  };

  const handleMaxFormationChange = (e) => {
    setMaxFormationLevel(e.target.value.toLowerCase());
  };

  const filterUsersByFormation = (currentUser) => {
    return currentUser.cvs.some(cv => cv.formations.some(formation => {
      const formationLevelValue = formationLevelsOrder[formation.niveau] || 0;
      const minLevelValue = formationLevelsOrder[minFormationLevel] || 0;
      const maxLevelValue = formationLevelsOrder[maxFormationLevel] || Infinity;

      return formationLevelValue >= minLevelValue && formationLevelValue <= maxLevelValue;
    }));
  };

  const handleSectorChange = (e) => {
    const selectedSector = e.target.value;
    if (selectedSector && !chips.some(chip => chip.label === selectedSector && chip.type === 'sector')) {
      setChips([...chips, { type: 'sector', label: selectedSector }]);
    }
  };

  const handleCountryChange = (e) => {
    const selectedCountry = e.target.value;
    if (selectedCountry && !chips.some(chip => chip.label === selectedCountry && chip.type === 'country')) {
      setChips([...chips, { type: 'country', label: selectedCountry }]);
    }
  };

  const handleRoleChange = (e) => {
    const selectedRole = e.target.value;
    if (selectedRole && !chips.some(chip => chip.label === selectedRole && chip.type === 'role')) {
      setChips([...chips, { type: 'role', label: selectedRole }]);
    }
  };

  useEffect(() => {
    const fetchUser = async () => {
      const response = await getAllUsers();
      const data = await response.json();
      setUsers(data);
      const managerList = data.map(user => user.manager)
        .filter((manager, index, self) =>
          manager !== null && // Verifica que el manager no sea null
          self.findIndex(m => m !== null && m.id === manager.id) === index
        );
      setManagers(managerList);
    };

    fetchUser();
  }, []);

  const filterUsersByLanguage = (cv, minLevel, maxLevel) => {
    if (!Array.isArray(cv.languages)) {
      return false;
    }

    return cv.languages.some(lang => {
      const levelValues = {
        oralComprehension: languageLevelsOrder[lang.oralComprehension] || 0,
        oralExpression: languageLevelsOrder[lang.oralExpression] || 0,
        writtenComprehension: languageLevelsOrder[lang.writtenComprehension] || 0,
        writtenExpression: languageLevelsOrder[lang.writtenExpression] || 0,
      };

      const minLevelValue = languageLevelsOrder[minLevel] || 0;
      const maxLevelValue = languageLevelsOrder[maxLevel] || Infinity;

      return (
        levelValues.oralComprehension >= minLevelValue &&
        levelValues.oralComprehension <= maxLevelValue &&
        levelValues.oralExpression >= minLevelValue &&
        levelValues.oralExpression <= maxLevelValue &&
        levelValues.writtenComprehension >= minLevelValue &&
        levelValues.writtenComprehension <= maxLevelValue &&
        levelValues.writtenExpression >= minLevelValue &&
        levelValues.writtenExpression <= maxLevelValue
      );
    });
  };

  const filterUsers = (currentUser) => {
    if (managerId && (!currentUser.manager || currentUser.manager.id !== parseInt(managerId))) {
      return false;
    }
    const passesChipCriteria = chips.every((chip) => {
      switch (chip.type) {
        case 'competence':
          const hasCompetence = currentUser.cvs.some(cv =>
            cv.cvCompetences.some(comp =>
              comp.competences.competenceName?.toLowerCase().includes(chip.label.toLowerCase())
            )
          );

          if (!hasCompetence) return false;

          const experiences = currentUser.cvs.flatMap(cv => cv.experiences || []);
          if (!experiences || experiences.length === 0) {
            console.error("User has no experiences:", currentUser);
            return false;
          }

          const durationInYears = calculateCompetenceSpecificDuration(experiences, chip.label);
          console.log(`Duration in years for ${chip.label}:`, durationInYears);

          return durationInYears >= chip.minYears && durationInYears <= chip.maxYears;
        case 'language':
          return currentUser.cvs.some(cv =>
            cv.languages.some(lang =>
              normalizeLanguageForSearch(lang.name).toLowerCase() === normalizeLanguageForSearch(chip.label).toLowerCase() &&
              filterUsersByLanguage(cv, minLanguageLevel, maxLanguageLevel)
            )
          );
        case 'sector':
          return currentUser.cvs.some(cv =>
            cv.experiences.some(exp =>
              exp.secteur && exp.secteur.toLowerCase().includes(chip.label.toLowerCase())
            )
          );
        case 'country':
          return currentUser.pays && currentUser.pays.toLowerCase().includes(chip.label.toLowerCase());
        case 'role':
          return currentUser.role && currentUser.role.toLowerCase().includes(chip.label.toLowerCase());
        case 'metier':
          return currentUser.businessUnits.some(bu =>
            bu.tribes.some(tribe =>
              tribe.metiers.some(metier =>
                metier.metierName.toLowerCase().includes(chip.label.toLowerCase())
              )
            )
          );
        default:
          return true;
      }
    });

    const passesExperienceCriteria = matchesExperienceLevel(currentUser, minYears, maxYears);

    const passesFormationCriteria = filterUsersByFormation(currentUser);

    return passesChipCriteria && passesExperienceCriteria && passesFormationCriteria;
  };

  const filteredUsers = users.filter(filterUsers);

  const [selectedIndex, setSelectedIndex] = useState(-1);

  //chips
  const handleKeyDown = (e) => {
    if (e.key === 'ArrowDown') {
      setSelectedIndex((prevIndex) => Math.min(prevIndex + 1, filteredCompetences.length - 1));
      e.preventDefault();
    } else if (e.key === 'ArrowUp') {
      setSelectedIndex((prevIndex) => Math.max(prevIndex - 1, 0));
      e.preventDefault();
    } else if (e.key === 'Enter') {
      if (selectedIndex >= 0 && selectedIndex < filteredCompetences.length) {
        handleSelectCompetence(filteredCompetences[selectedIndex].competenceName);
      }
      e.preventDefault();
    }
  };

  const handleMetierKeyDown = (e) => {
    if (e.key === 'ArrowDown') {
      setSelectedIndex((prevIndex) => Math.min(prevIndex + 1, filteredMetiers.length - 1));
      e.preventDefault();
    } else if (e.key === 'ArrowUp') {
      setSelectedIndex((prevIndex) => Math.max(prevIndex - 1, 0));
      e.preventDefault();
    } else if (e.key === 'Enter') {
      if (selectedIndex >= 0 && selectedIndex < filteredMetiers.length) {
        handleMetierClick(filteredMetiers[selectedIndex].metierName);
      }
      e.preventDefault();
    }
  };

  const removeChip = (chipToRemove) => {
    setChips(chips.filter(chip => chip !== chipToRemove));
  };

  const handleSelectCompetence = (competenceName) => {
    if (competenceName && !chips.some(chip => chip.label === competenceName && chip.type === 'competence')) {
      const newChip = { type: 'competence', label: competenceName, minYears: 0, maxYears: 99 };
      setChips([...chips, newChip]);
    }
    setSearchByCompetence('');
    setFilteredCompetences([]);
  };

  const handleYearChange = (index, type, value) => {
    const numericValue = Math.max(0, parseInt(value, 10));

    setChips(prevChips => prevChips.map((chip, i) => {
      if (i === index && chip.type === 'competence') {
        return { ...chip, [type]: numericValue };
      }
      return chip;
    }));
  };

  const clearSearch = () => {
    setChips([]);

  };

  //Dropdown
  const [dropdownStates, setDropdownStates] = useState({});
  const [selectedUserCvs, setSelectedUserCvs] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);

  const handleUserClick = (user) => {
    const isCurrent = selectedUser && selectedUser.id === user.id;
    const newState = !dropdownStates[user.id];

    setDropdownStates(prev => ({
      ...prev,
      [user.id]: !isCurrent ? true : false
    }));

    if (!isCurrent) {
      setSelectedUser(user);
      setSelectedUserCvs(user.cvs);
    } else {
      setSelectedUser(null);
      setSelectedUserCvs([]);
    }
  };

  useEffect(() => {
    const fetchAllMetiers = async () => {
      try {
        const response = await http('business-units/metiers');
        const data = await response.json();
        setAllMetiers(data);
        setFilteredMetiers(data);
      } catch (error) {
        console.error('Failed to fetch metiers:', error);
      }
    };
    fetchAllMetiers();
  }, []);

  useEffect(() => {
    if (selectedMetier) {
      setFilteredMetiers(allMetiers.filter(metier =>
        metier.metierName.toLowerCase().includes(selectedMetier.toLowerCase())
      ));
    } else {
      setFilteredMetiers(allMetiers);
    }
  }, [selectedMetier, allMetiers]);

  const addMetierChip = (metierName) => {
    if (!chips.some(chip => chip.label === metierName && chip.type === 'metier')) {
      setChips([...chips, { type: 'metier', label: metierName }]);
    }
  };

  const handleMetierClick = (metierName) => {
    setSelectedMetier('');
    addMetierChip(metierName);
  };

  return (
    <MDBContainer fluid className={isDarkMode ? 'bg-dark-mode' : 'bg-light'}>
      <MDBContainer className="animation-fade-in pb-5">
        <div
          className="p-5 bg-image"
          style={{
            height: "300px",
            backgroundImage: 'url("./bg/recherche-avance.jpg")',
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center bottom",
            backgroundAttachment: "fixed",
          }}
        ></div>
        <MDBRow className="text-center pt-3">
          <div className={isDarkMode ? "text-white" : "text-muted"}>
            <h1 className="py-3">{translations.search}</h1>
          </div>
          <div className="d-flex align-items-center me-4 justify-content-center">
            <i className="flag flag-france"></i>
            <Switch
              checked={selectedLanguage === 'EN'}
              onChange={handleSelectLanguageChange}
              name="languageSwitch"
              inputProps={{ 'aria-label': 'language switch' }}
            />
            <i className="flag flag-united-kingdom"></i>
          </div>
        </MDBRow>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>

          <div className="competence-suggestions-container-recherche">
            <p className={`text-center ${isDarkMode ? "text-white" : "text-muted"}`}>{translations.metier}</p>
            <MDBInputGroup className='mb-3' noBorder textBefore={<MDBIcon fas icon='search' className={isDarkMode ? "text-white" : "text-muted"} />}>
              <input
                className={`form-control ${isDarkMode ? "text-white" : "text-muted"}`}
                style={{ backgroundColor: 'transparent' }}
                type="text"
                placeholder={translations.searchBy}
                value={selectedMetier}
                onChange={(e) => setSelectedMetier(e.target.value)}
                onKeyDown={handleMetierKeyDown}
              />
              {selectedMetier && (
                <div className="suggestions-recherche">
                  {filteredMetiers.map((metier, index) => (
                    <div key={metier.metierId} className={`suggestion-item-recherche ${index === selectedIndex ? 'selected' : ''}`} onClick={() => handleMetierClick(metier.metierName)}>
                      {metier.metierName}
                    </div>
                  ))}
                </div>
              )}
            </MDBInputGroup>
          </div>
          <div className="competence-suggestions-container-recherche">
            <p className={`text-center ${isDarkMode ? "text-white" : "text-muted"}`}>{translations.businessSkills}</p>
            <MDBInputGroup className='mb-3' noBorder textBefore={<MDBIcon fas icon='search' className={isDarkMode ? "text-white" : "text-muted"} />}>
              <input className={`form-control ${isDarkMode ? "text-white" : "text-muted"}`} style={{ backgroundColor: 'transparent' }} type='text' placeholder={translations.searchBy} value={searchByCompetence}
                onChange={handleCompetenceChange}
                onKeyDown={handleKeyDown}
              />
              {searchByCompetence && (
                <div className="suggestions-recherche">
                  {filteredCompetences.map((comp, index) => (
                    <div key={index} className={`suggestion-item-recherche ${index === selectedIndex ? 'selected' : ''}`} onClick={() => handleSelectCompetence(comp.competenceName)}>
                      {comp.competenceName}
                    </div>
                  ))}
                </div>
              )}
            </MDBInputGroup>
          </div>
          <div>
            <p className={`text-center ${isDarkMode ? "text-white" : "text-muted"}`}>{translations.languages}</p>
            <MDBInputGroup className='mb-3' noBorder textBefore={<MDBIcon fas icon='search' className={isDarkMode ? "text-white" : "text-muted"} />}>
              <SelectLanguage
                id="language-select"
                selectedLanguage={searchByLanguage}
                handleLanguageChange={handleLanguageChange}
                translations={translations}
                className={isDarkMode ? "text-white" : "text-muted"}
              />
            </MDBInputGroup>
          </div>
          <div data-role="main">
            <div>
              <p className={`text-center ${isDarkMode ? "text-white" : "text-muted"}`}>{translations.levelExp}</p>
              <div className="d-flex">
                <div className="">
                  <MDBInputGroup className='mb-3' noBorder textBefore={<span className={isDarkMode ? "text-white" : "text-muted"}>Min</span>}>
                    <input type="number" style={{ width: "50px" }} value={minYears} onChange={(e) => setMinYears(Number(e.target.value))} min="0" max="99" />
                  </MDBInputGroup>
                </div>
                <div className="">
                  <MDBInputGroup className='mb-3' noBorder textBefore={<span className={isDarkMode ? "text-white" : "text-muted"}>Max</span>}>
                    <input type="number" style={{ width: "50px" }} value={maxYears} onChange={(e) => setMaxYears(Number(e.target.value))} min="0" max="99" />
                  </MDBInputGroup>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="mb-3 text-center">
            <MDBBtn onClick={handleButtonClick} className="btn_primary">
              <MDBIcon fas icon="angle-down" className="me-2" style={{ transition: "transform 0.2s", transform: showElements ? "rotate(180deg)" : "rotate(0deg)" }} />
              {translations.criteria}
            </MDBBtn>
          </div>
          {showElements && (
            <div className="d-flex justify-content-center gap-5">
              <div>
                <p className={`text-center ${isDarkMode ? "text-white" : "text-muted"}`}>{translations.levelForm}</p>
                <div className="d-flex gap-2">
                  <MDBInputGroup className='mb-3' noBorder textBefore={<span className={isDarkMode ? "text-white" : "text-muted"}>Min</span>}>
                    <select className={`form-control ${isDarkMode ? "text-white" : "text-muted"}`} style={{ backgroundColor: 'transparent' }} type='text' onChange={handleMinFormationChange}>
                      <option defaultValue>{translations.filterBy}</option>
                      <option>{translations.educationLevels.BAC}</option>
                      <option>{translations.educationLevels.BAC1}</option>
                      <option>{translations.educationLevels.BAC2}</option>
                      <option>{translations.educationLevels.BAC3}</option>
                      <option>{translations.educationLevels.BAC4}</option>
                      <option>{translations.educationLevels.BAC5}</option>
                      <option>{translations.educationLevels.BAC67}</option>
                      <option>{translations.educationLevels.BAC8}</option>
                    </select>
                  </MDBInputGroup>
                  <MDBInputGroup className='mb-3' noBorder textBefore={<span className={isDarkMode ? "text-white" : "text-muted"}>Max</span>}>
                    <select className={`form-control ${isDarkMode ? "text-white" : "text-muted"}`} style={{ backgroundColor: 'transparent' }} type='text' onChange={handleMaxFormationChange}>
                      <option defaultValue>{translations.filterBy}</option>
                      <option>{translations.educationLevels.BAC}</option>
                      <option>{translations.educationLevels.BAC1}</option>
                      <option>{translations.educationLevels.BAC2}</option>
                      <option>{translations.educationLevels.BAC3}</option>
                      <option>{translations.educationLevels.BAC4}</option>
                      <option>{translations.educationLevels.BAC5}</option>
                      <option>{translations.educationLevels.BAC67}</option>
                      <option>{translations.educationLevels.BAC8}</option>
                    </select>
                  </MDBInputGroup>
                </div>
              </div>
              <div>
                <p className={`text-center ${isDarkMode ? "text-white" : "text-muted"}`}>{translations.labels.sector}</p>
                <MDBInputGroup className='mb-5' noBorder textBefore={<MDBIcon fas icon='search' className={isDarkMode ? "text-white" : "text-muted"} />}>
                  <select className={`form-control ${isDarkMode ? "text-white" : "text-muted"}`} style={{ backgroundColor: 'transparent' }} type='text' placeholder="Secteur d'activité"
                    value={selectedSector}
                    onChange={handleSectorChange}
                  >
                    <option value="Secteur d'activité">{translations.searchBy}</option>
                    <option value="Agroalimentaire">{translations.sectorActivite.Agroalimentaire}</option>
                    <option value="Aeronautique">{translations.sectorActivite.Aeronautique}</option>
                    <option value="ArtsSpectacle">{translations.sectorActivite.ArtsSpectacle}</option>
                    <option value="Assurance">{translations.sectorActivite.Assurance}</option>
                    <option value="Automobile">{translations.sectorActivite.Automobile}</option>
                    <option value="Banque">{translations.sectorActivite.Banque}</option>
                    <option value="BTP">{translations.sectorActivite.BTP}</option>
                    <option value="Distribution">{translations.sectorActivite.Distribution}</option>
                    <option value="Energies">{translations.sectorActivite.Energies}</option>
                    <option value="Enseignement">{translations.sectorActivite.Enseignement}</option>
                    <option value="Etudes">{translations.sectorActivite.Etudes}</option>
                    <option value="Services">{translations.sectorActivite.Services}</option>
                    <option value="Immobilier">{translations.sectorActivite.Immobilier}</option>
                    <option value="Pharmaceutique">{translations.sectorActivite.Pharmaceutique}</option>
                    <option value="Informatiques">{translations.sectorActivite.Informatiques}</option>
                    <option value="InstitutionsEuropeennes">{translations.sectorActivite.InstitutionsEuropeennes}</option>
                    <option value="Logistique">{translations.sectorActivite.Logistique}</option>
                    <option value="Industries">{translations.sectorActivite.Industries}</option>
                    <option value="Sante">{translations.sectorActivite.Sante}</option>
                    <option value="SecteurPublic">{translations.sectorActivite.SecteurPublic}</option>
                    <option value="Restauration">{translations.sectorActivite.Restauration}</option>
                    <option value="Tourisme">{translations.sectorActivite.Tourisme}</option>
                    <option value="Transport">{translations.sectorActivite.Transport}</option>
                  </select>
                </MDBInputGroup>
              </div>
              <div>
                <p className={`text-center ${isDarkMode ? "text-white" : "text-muted"}`}>{translations.countryCo}</p>
                <MDBInputGroup className='mb-5' noBorder textBefore={<MDBIcon fas icon='search' className={isDarkMode ? "text-white" : "text-muted"} />}>
                  <select className={`form-control ${isDarkMode ? "text-white" : "text-muted"}`} style={{ backgroundColor: 'transparent' }} type='text' placeholder="Pays de rattachement"
                    value={selectedCountry}
                    onChange={handleCountryChange}
                  >
                    <option className="text-white">{translations.searchBy}</option>
                    <option className="text-white" value="France">France</option>
                    <option className="text-white" value="Luxembourg">Luxembourg</option>
                    <option className="text-white" value="Switzerland">{translations.suisse}</option>
                    <option className="text-white" value="Belgique">{translations.belgium}</option>
                  </select>
                </MDBInputGroup>
              </div>
              <div>
                <p className={`text-center ${isDarkMode ? "text-white" : "text-muted"}`}>Manager</p>
                <MDBInputGroup className='mb-5' noBorder textBefore={<MDBIcon fas icon='search' className={isDarkMode ? "text-white" : "text-muted"} />}>
                  <select
                    value={managerId}
                    onChange={handleManagerChange}
                    className={`form-control ${isDarkMode ? "text-white" : "text-muted"}`}
                    style={{ backgroundColor: 'transparent' }}
                  >
                    <option value="">{translations.searchBy}</option>
                    {managers.map((manager, index) => (
                      <option key={index} value={manager.id}>
                        {manager.prenom} {manager.nom}
                      </option>
                    ))}
                  </select>
                </MDBInputGroup>
              </div>
              <div>
                <p className={`text-center ${isDarkMode ? "text-white" : "text-muted"}`}>{translations.role}</p>
                <MDBInputGroup className='mb-3' noBorder textBefore={<MDBIcon fas icon='search' className={isDarkMode ? "text-white" : "text-muted"} />}>
                  <select
                    className={`form-control ${isDarkMode ? "text-white" : "text-muted"}`}
                    style={{ backgroundColor: 'transparent' }}
                    aria-label="Sélectionnez la tribe"
                    value={selectedRole}

                    onChange={handleRoleChange}
                  >
                    <option value="">{translations.searchBy}</option>
                    <option value="Candidat">Candidat</option>
                    <option value="Consultant">Consultant</option>
                    <option value="Manager">Manager</option>
                    <option value="Super Admin">Super Admin</option>
                  </select>
                </MDBInputGroup>
              </div>
            </div>
          )}
        </div>
        <div className="text-center">
          <h3 className={isDarkMode ? "text-white" : "text-muted"}>{translations.result}</h3>
          <button className="btn btn-danger" onClick={clearSearch} style={{ backgroundColor: '#f64915' }}>
            <MDBIcon fas icon="trash-alt" /> {translations.clear}
          </button>
          <div className="chips-container text-white mt-3">
            <div className="chip-container">
              {chips
                .filter(chip => chip.type !== 'competence' && chip.type !== 'language')
                .map((chip, index) => (
                  <div key={index} className="chip">
                    {chip.label} <span className="chip-close" onClick={() => removeChip(chip)}>×</span>
                  </div>
                ))}
            </div>
            <div className="competence-container">
              {chips
                .filter(chip => chip.type === 'competence')
                .map((chip, index) => (
                  <div className="row">
                    <div className="col-4">
                      <div key={index} className="chip">
                        {chip.label} <span className="chip-close" onClick={() => removeChip(chip)}>×</span>
                      </div>
                    </div>
                    <div className="col-3">
                      <MDBInputGroup className='mb-3' noBorder textBefore={<span className={isDarkMode ? "text-white" : "text-muted"}>Min</span>}>
                        <input type="number" value={chip.minYears} onChange={(e) => handleYearChange(index, 'minYears', e.target.value)} />
                      </MDBInputGroup>
                    </div>
                    <div className="col-3">
                      <MDBInputGroup className='mb-3' noBorder textBefore={<span className={isDarkMode ? "text-white" : "text-muted"}>Max</span>}>
                        <input type="number" value={chip.maxYears} onChange={(e) => handleYearChange(index, 'maxYears', e.target.value)} />
                      </MDBInputGroup>
                    </div>
                  </div>
                ))}
            </div>
            <div className="competence-container">
              {chips
                .filter(chip => chip.type === 'language')
                .map((chip, index) => (
                  <div className="row">
                    <div className="col-4">
                      <div key={index} className="chip">
                        {chip.label} <span className="chip-close" onClick={() => removeChip(chip)}>×</span>
                      </div>
                    </div>
                    <div className="col-3">
                      <MDBInputGroup className='mb-3' noBorder textBefore={<span className={isDarkMode ? "text-white" : "text-muted"}>Min</span>}>
                        <select
                          className={`form-control ${isDarkMode ? "text-white" : "text-muted"}`}
                          style={{ backgroundColor: 'transparent' }}
                          aria-label="Sélectionnez le niveau d'expérience"
                          onChange={handleMinLanguageChange}
                        >
                          <option value="">{translations.filterBy}</option>
                          <option value="motherTongue">{translations.labels.motherTongue}</option>
                          <option value="A1">A1</option>
                          <option value="A2">A2</option>
                          <option value="B1">B1</option>
                          <option value="B2">B2</option>
                          <option value="C1">C1</option>
                          <option value="C2">C2</option>
                        </select>
                      </MDBInputGroup>
                    </div>
                    <div className="col-3">
                      <MDBInputGroup className='mb-3' noBorder textBefore={<span className={isDarkMode ? "text-white" : "text-muted"}>Max</span>}>
                        <select
                          className={`form-control ${isDarkMode ? "text-white" : "text-muted"}`}
                          style={{ backgroundColor: 'transparent' }}
                          aria-label="Sélectionnez le niveau d'expérience"
                          onChange={handleMaxLanguageChange}
                        >
                          <option value="">{translations.filterBy}</option>
                          <option value="motherTongue">{translations.labels.motherTongue}</option>
                          <option value="A1">A1</option>
                          <option value="A2">A2</option>
                          <option value="B1">B1</option>
                          <option value="B2">B2</option>
                          <option value="C1">C1</option>
                          <option value="C2">C2</option>
                        </select>
                      </MDBInputGroup>
                    </div>
                  </div>
                ))}
            </div>
          </div>
          {
            (chips.length > 0 || minYears !== 1 || maxYears !== 30 || minFormationLevel || maxFormationLevel) ? (
              <MDBTable>
                <MDBTableBody>
                  {filteredUsers.length > 0 ? (
                    filteredUsers.map((user) => (
                      <React.Fragment key={user.id}>
                        <tr className={isDarkMode ? "text-white" : "text-muted"}>
                          <td>
                            <div className="d-flex align-items-center" onClick={() => handleUserClick(user)} style={{ cursor: "pointer" }}>
                              <MDBIcon fas icon="angle-down" style={{
                                transition: "transform 0.3s",
                                transform: dropdownStates[user.id] ? "rotate(180deg)" : "rotate(0deg)"
                              }} />
                              <img
                                src="/spinner-alcyone.png"
                                alt=""
                                style={{ width: "45px", height: "45px" }}
                                className="rounded-circle"
                              />
                              <a
                                href={`/user-cvs/${user.id}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ cursor: "pointer", textDecoration: "none", color: "inherit" }}
                              >
                                <p className="noLinkStyle fw-bold mb-1">
                                  <span className="text-uppercase">
                                    {user.nom}
                                  </span>{" "}
                                  {user.prenom}
                                </p>
                              </a>
                            </div>
                          </td>
                          <td>
                          </td>
                          <td>
                          </td>
                          <td>
                          </td>
                        </tr>
                        {dropdownStates[user.id] && selectedUser && selectedUser.id === user.id && selectedUserCvs.length > 0 && (
                          selectedUserCvs.map((cv, index) => (
                            <tr key={index}>
                              <td>
                                <a
                                  href={`/mon-espace-cv/${user.id}/${cv.id}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  style={{ cursor: "pointer", textDecoration: "none", color: "inherit" }}
                                >
                                  <img
                                    src="./spinner-alcyone.png"
                                    alt=""
                                    style={{ width: "45px", height: "45px" }}
                                    className="rounded-circle"
                                  />
                                  <MDBBadge color="info" light>
                                    ACO_{user.prenom ? user.prenom : "-"}_
                                    <span className="text-uppercase">
                                      {user.nom ? user.nom : "-"}
                                    </span>
                                    _CV_
                                    <span className="text-uppercase">
                                      {cv.langues ? cv.langues : "-"}
                                    </span>
                                  </MDBBadge>
                                </a>
                              </td>
                              <td>
                                <p className="fw-normal mb-1 text-uppercase">
                                  {cv.langues}
                                </p>
                              </td>
                              <td>
                                <p className="mb-0">{cv.mot_cle}</p>
                              </td>
                              <td>
                                <p className="fw-normal mb-1">
                                  {new Date(cv.date_creation).toLocaleDateString(
                                    "fr-FR"
                                  )}
                                </p>
                              </td>
                            </tr>
                          ))
                        )}
                      </React.Fragment>
                    ))
                  ) : (
                    <tr>
                      <td>
                        <p className={isDarkMode ? "text-white" : "text-muted"}>{translations.infobulle.rechercheAvance}</p>
                      </td>
                    </tr>
                  )}
                </MDBTableBody>
              </MDBTable>

            ) : (
              <p className={isDarkMode ? "text-white" : "text-muted"}>{translations.infobulle.rechercheAvance}</p>
            )
          }
        </div>
      </MDBContainer>
    </MDBContainer >
  );
}
